import {
  EmailInputRegExp,
  EmailRegExp,
  INNInputRegexp,
  INNRegexp,
  OGRNInputRegexp,
  OGRNRegexp,
  OnlySymbolInputRegExp,
  OnlySymbolRegExp,
  PasswordRegExp,
  PhoneWithMaskRegExp,
  cyrillicRegExp,
} from "@/Features/User/lib/validate";
import * as Yup from "yup";

export const personalAccount = {
  personal: {
    validation: Yup.object().shape({
      first_name: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .max(30, "common:form.validate.fromAndToLength")
        .matches(OnlySymbolRegExp, "common:form.validate.fromAndToLength"),
      last_name: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .max(30, "common:form.validate.fromAndToLength")
        .matches(OnlySymbolRegExp, "common:form.validate.fromAndToLength"),
      phone: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .matches(PhoneWithMaskRegExp, "common:form.validate.phone"),
      email: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .test("cyrillic", "common:form.validate.notCyrillic", (value) => {
          return !cyrillicRegExp.test(value);
        })
        .matches(EmailRegExp, "common:form.validate.email")
        .email("common:form.validate.email")
        .max(100, "common:form.validate.emailMaxLength"),
      password: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .min(6, "common:form.validate.password")
        .matches(PasswordRegExp, "common:form.validate.password"),
      terms_and_conditions: Yup.boolean().oneOf([true], "common:form.validate.required"),
    }),
    fields: [
      {
        name: "first_name",
        type: "text",
        label: "common:form.field.name",
        maxLength: 30,
        regexp: OnlySymbolInputRegExp,
        required: true,
      },
      {
        name: "last_name",
        type: "text",
        label: "common:form.field.last_name",
        maxLength: 30,
        regexp: OnlySymbolInputRegExp,
        required: true,
      },
      {
        name: "phone",
        type: "text",
        label: "common:form.field.phone",
        required: true,
        mask: "+7 (900) 000-00-00",
        defaultValue: "+7 (9__) ___-__-__",
      },
      {
        name: "email",
        type: "text",
        label: "common:form.field.email",
        maxLength: 100,
        regexp: EmailInputRegExp,
        autocomplete: "new-email",
        required: true,
      },
      {
        name: "password",
        type: "password",
        label: "common:form.field.password",
        autocomplete: "new-password",
        required: true,
      },
    ],
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      terms_and_conditions: false,
    },
  },
  company: {
    validation: Yup.object().shape({
      company_name: Yup.string()
        .trim()
        .required("common:form.validate.companyNameRequired")
        .max(100, "common:form.validate.companyNameMax"),
      email: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .test("cyrillic", "common:form.validate.notCyrillic", (value) => {
          return !cyrillicRegExp.test(value);
        })
        .matches(EmailRegExp, "common:form.validate.email")
        .email("common:form.validate.email")
        .max(100, "common:form.validate.emailMaxLength"),
      last_name: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .max(30, "common:form.validate.fromAndToLength")
        .matches(OnlySymbolRegExp, "common:form.validate.fromAndToLength"),
      first_name: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .max(30, "common:form.validate.fromAndToLength")
        .matches(OnlySymbolRegExp, "common:form.validate.fromAndToLength"),
      middle_name: Yup.string()
        .trim()
        .max(30, "common:form.validate.fromAndToLength")
        .matches(OnlySymbolRegExp, "common:form.validate.fromAndToLength"),
      inn: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .min(10, "common:form.validate.innMaxMin")
        .max(12, "common:form.validate.innMaxMin")
        .matches(INNRegexp, "common:form.validate.innValidation"),
      ogrn: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .min(13, "common:form.validate.ogrnLength")
        .matches(OGRNRegexp, "common:form.validate.ogrnValidation"),
      password: Yup.string()
        .trim()
        .required("common:form.validate.required")
        .min(6, "common:form.validate.password")
        .matches(PasswordRegExp, "common:form.validate.password"),
      terms_and_conditions: Yup.boolean().oneOf([true], "common:form.validate.required"),
    }),
    fields: [
      {
        name: "company_name",
        type: "text",
        label: "common:form.field.company_name",
        maxLength: 100,
        required: true,
      },
      {
        name: "email",
        type: "text",
        label: "common:form.field.company_email",
        regexp: EmailInputRegExp,
        required: true,
      },
      {
        name: "first_name",
        type: "text",
        label: "common:form.field.name",
        regexp: OnlySymbolInputRegExp,
        required: true,
      },
      {
        name: "last_name",
        type: "text",
        label: "common:form.field.last_name",
        regexp: OnlySymbolInputRegExp,
        required: true,
      },
      {
        name: "middle_name",
        type: "text",
        label: "common:form.field.surname",
        regexp: OnlySymbolInputRegExp,
        required: false,
      },
      {
        name: "inn",
        type: "text",
        label: "common:form.field.inn",
        regexp: INNInputRegexp,
        required: true,
      },
      {
        name: "ogrn",
        type: "text",
        label: "common:form.field.ogrn",
        regexp: OGRNInputRegexp,
        required: true,
      },
      {
        name: "password",
        type: "password",
        label: "common:form.field.password",
        required: true,
      },
    ],
    initialValues: {
      company_name: "",
      email: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      inn: "",
      ogrn: "",
      password: "",
      terms_and_conditions: false,
    },
  },
};
