import { Trans, useTranslation } from "react-i18next";
import Buttons from "@/Components/Button/Buttons";

export const AccessConfirm = ({ email, onCancel }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center">
        <i className="fa-regular fa-envelope text-[60px]"></i>
      </div>
      <h1 className="text-[40px] m-0">
        <span className="text-primary-main">{t("page.access_confirm.title.bold")}</span>{" "}
        <span>{t("page.access_confirm.title.other")}</span>
      </h1>

      <div className="my-3 text-secondary leading-6">
        <div>
          <Trans i18nKey="page.access_confirm.description.manual">{{ email }}</Trans>
        </div>
      </div>

      <Buttons
        onClick={onCancel}
        title={t("common:sign_in")}
        ariaLabel="login"
        type="submit"
        className="btn-fill btn-fancy w-full font-medium font-serif rounded-none uppercase mt-4"
        themeColor="#232323"
        color="#fff"
        size="md"
      />
    </>
  );
};
