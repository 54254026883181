import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ProfileButton = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx("inline-block text-base-main", className)}>
      {
        <Link to="/dashboard" title={t("common:profile")} className="ml-[15px] hover:opacity-60">
          <button>{t("common:personal_account")}</button>
        </Link>
      }
    </div>
  );
};
