import { getUserInfo, useUser } from "@/Features/User";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

/**
 * @param {{
 *    getCondition: (user: ReturnType<typeof useUser>) => boolean,
 *    redirect: string
 * }} props
 * */
export const AuthGate = ({ getCondition, redirect = "/signin" }) => {
  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  if (user.state.isLoading)
    return (
      <div className="fixed left-2/4 top-2/4 translate-x-2/4 translate-y-2/4">
        <Spinner />
      </div>
    );

  if (getCondition(user)) return <Outlet />;

  return <Navigate to={redirect} replace />;
};
