import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useMemo, useState } from "react";

import Buttons from "@/Components/Button/Buttons";
import { Checkbox, Input, InputWithMask, Label } from "@/Components/Form/Form";
import { UserApi } from "@/Api";
import {
  EMAIL_IS_BUSY_AND_CONFIRMED,
  EMAIL_IS_BUSY_BUT_NOT_CONFIRMED,
  UNKNOWN_SERVER_ERROR,
  useErrors,
} from "@/Features/Errors";

import { personalAccount } from "@/Utils/signUpForm";
import { removePhoneSymbols } from "@/Functions/Utilities";
import clsx from "clsx";

export const SignUpForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getErrorByCode } = useErrors();
  const [selectedAccount, setSelectedAccount] = useState("company");

  const { validation, fields, initialValues } = useMemo(
    () => personalAccount[selectedAccount],
    [selectedAccount]
  );

  const [error, setError] = useState("");

  const handleSubmit = async (values) => {
    try {
      if (selectedAccount === "personal") {
        await UserApi.personalSignUp({
          ...values,
          phone: Number(removePhoneSymbols(values.phone)),
        });
      } else {
        delete values.terms_and_conditions;
        await UserApi.companySignUp({
          ...values,
          inn: Number(values.inn),
          ogrn: Number(values.ogrn),
        });
      }
      navigate("/signin", { state: { email: values.email } });
    } catch (e) {
      const errorCode = e.response.data?.error?.code;
      const errorMsg = getErrorByCode(errorCode);

      switch (errorCode) {
        case EMAIL_IS_BUSY_BUT_NOT_CONFIRMED:
        case EMAIL_IS_BUSY_AND_CONFIRMED:
          return setError(t("common:error_email_already_registered"));
        case UNKNOWN_SERVER_ERROR:
          return setError(t("common:internal_server_error"));
        default:
          if (errorMsg) {
            return setError(errorMsg);
          }
      }
    }
  };

  const handleFocusForm = (e) => {
    if (e.target.nodeName !== "INPUT") return;

    setError("");
  };

  return (
    <Formik
      key={selectedAccount}
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, isValid, dirty }) => (
        <Form noValidate onFocus={handleFocusForm}>
          <h1 className="text-xmd font-bold leading-[20px] text-center">
            {t("page.sign_up.title")}
          </h1>

          <ul className="nav justify-content-center mb-8">
            <li
              className={clsx("nav-item btn-link hover:text-base-main hover:opacity-5 btn", {
                "after:bg-primary-main after:h-[2px] md:text-md": selectedAccount === "company",
              })}
            >
              <span
                className="nav-link text-black"
                role="button"
                onClick={() => {
                  resetForm();
                  setSelectedAccount("company");
                }}
              >
                {t("company_account")}
              </span>
            </li>
            <li
              className={clsx("nav-item btn-link hover:text-base-main hover:opacity-5 btn", {
                "after:bg-primary-main after:h-[2px] md:text-md": selectedAccount === "personal",
              })}
            >
              <span
                className="nav-link text-black"
                role="button"
                onClick={() => {
                  resetForm();
                  setSelectedAccount("personal");
                }}
              >
                {t("personal_account")}
              </span>
            </li>
          </ul>

          <section className="flex flex-col gap-3">
            {fields.map((field, key) =>
              field.mask ? (
                <InputWithMask
                  name={field.name}
                  type={field.type}
                  label={<Label title={t(field.label)} required={field.required} />}
                  className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
                  autocomplete={field.autocomplete}
                  key={key}
                  mask={field.mask}
                  defaultValue={field.defaultValue}
                />
              ) : (
                <Input
                  name={field.name}
                  type={field.type}
                  label={<Label title={t(field.label)} required={field.required} />}
                  className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
                  maxLength={field?.maxLength}
                  matchingExpression={field.regexp}
                  autocomplete={field.autocomplete}
                  key={key}
                />
              )
            )}

            <Checkbox name="terms_and_conditions" className="mr-3" labelClass="mr-auto">
              <p className="text-[0.8rem] leading-snug">
                <Trans
                  i18nKey="common:form.field.terms_of_service"
                  components={[
                    <Link
                      className="underline"
                      target="_blank"
                      to="/assets/documents/terms_and_conditions.pdf"
                    />,
                    <Link
                      className="underline"
                      target="_blank"
                      to="/assets/documents/privacy_policy.pdf"
                    />,
                  ]}
                />
              </p>
            </Checkbox>

            {error && <div className="text-error leading-tight text-center">{error}</div>}

            <Buttons
              ariaLabel="login"
              type="submit"
              className="btn-fill btn-fancy w-full font-medium font-serif rounded-none uppercase mt-4"
              themeColor="#232323"
              color="#fff"
              size="md"
              title={t("page.sign_up.submit")}
              disabled={isSubmitting || !(isValid && dirty)}
            />
            <div className="mt-[20px] text-center">
              <Link
                to="/signin"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.sign_up.there_is_an_account")}
              </Link>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};
