import { api } from "@/Api/api";

import { logOut } from "./userSlice";
import { isRejectedWithValue } from "@reduxjs/toolkit";

let refreshPromise;

/**
 * @param {Object} store
 * @param {RootState} store.getState
 */
export const authMiddleware = ({ dispatch }) => {
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;

      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          if (!refreshPromise) {
            refreshPromise = api.post(`/auth/refresh`, undefined, originalConfig);
          }

          const res = await refreshPromise;
          refreshPromise = undefined;

          if (res.data?.error) throw new Error("Unauthorized");
          return api(originalConfig);
        } catch (error) {
          dispatch(logOut());
          console.log(error);
        }
      }

      return Promise.reject(error);
    }
  );

  return (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        dispatch(logOut());
      }
    }

    return next(action);
  };
};
